@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E5E5E5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Urbanist;
  src: url('./assets/fonts/Urbanist-Regular.ttf');
}

.font-Urbanist {
  font-family: Urbanist;
}

@font-face {
  font-family: Montserrat;
  src: url('./assets/fonts/Montserrat-Regular.ttf');
}

.font-Montserrat {
  font-family: Montserrat;
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('./assets/fonts/Montserrat-Bold.ttf');
}

.font-Montserrat-Bold {
  font-family: Montserrat-Bold;
}


@font-face {
  font-family: Inter;
  src: url('./assets/fonts/Inter-Regular.otf');
}

.font-Inter {
  font-family: Inter;
}


progress {
  border-radius: 40px;
  width: 100%;
  height: 16px;
}

progress::-webkit-progress-bar {
  background-color: #F4F4F6;
  border-radius: 8px;
}

progress::-webkit-progress-value {
  border-radius: 8px;
  background-color: #52BC1F;
}

progress::-moz-progress-bar {
  /* style rules */
}

.Toastify__toast-container {
  z-index: 10001 !important;
}


.project-timeline .swiper {
  overflow: inherit;
}
.project-timeline .swiper-button-prev,
.project-timeline .swiper-button-next {
  color: rgb(8, 185, 8);
  top: 0;
}
.project-timeline .swiper-button-prev {
  left: -8px;
}
.project-timeline .swiper-button-next {
  right: -8px;
}
.project-timeline .swiper-button-disabled {
  display: none;
}
.project-timeline .swiper-button-prev::after,
.project-timeline .swiper-button-next::after {
  font-size: 20px;
}