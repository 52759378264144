.blog-details p {
  margin: 16px 0;
}


.blog-item .swiper {
  overflow: inherit;
  padding: 0 20px;
}
.blog-item .swiper-button-prev,
.blog-item .swiper-button-next {
  color: blue;
  background: #f7f7fc;
}
.blog-item .swiper-button-prev {
  left: -8px;
}
.blog-item .swiper-button-next {
  right: -8px;
}
.blog-item .swiper-button-disabled {
  display: none;
}
.blog-item .swiper-button-prev::after,
.blog-item .swiper-button-next::after {
  font-size: 20px;
}
.blog-item.mobile .swiper-button-prev,
.blog-item.mobile .swiper-button-next {
  display: none;
}